import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'zui-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: [ './action-list-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionListItemComponent {

  @Input()
  icon: string;

  @Input()
  iconSize = 20;

  @Input()
  iconColor: string;

  @Input()
  iconFaded = true;

  @Input()
  disableLabel = false;

  @Input()
  afterLabelIcon: string;

  @Input()
  label: string;

  @Input()
  isLoading = false;

  @Input()
  mode: 'normal' | 'dense' = 'normal';

  @Input()
  set warn(v: string | boolean) {
    this._warn = coerceBooleanProperty(v);
  }
  get warn(): boolean { return this._warn; }

  @Input()
  set hideContent(v: string | boolean) {
    this._hideContent = coerceBooleanProperty(v);
  }
  get hideContent(): boolean {
    return this._hideContent;
  }

  private _warn = false;
  private _hideContent = false;

}
