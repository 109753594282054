<zui-progress-spinner
  *ngIf="icon"
  class="__progress"
  [class.has-label]="!disableLabel"
  [matTooltip]="label"
  [matTooltipDisabled]="!disableLabel"
  [hideContent]="hideContent"
  [diameter]="label ? 18 : 20"
  [isLoading]="isLoading">
  <mat-icon
    [ngStyle]="{
      'width.px': mode === 'dense' ? 16 : iconSize,
      'height.px': mode === 'dense' ? 16 : iconSize,
      'lineHeight.px': mode === 'dense' ? 13 : iconSize,
      'fontSize.px': mode === 'dense' ? 16 : iconSize,
      'color': iconColor ? iconColor : undefined
    }"
    class="__icon"
    fontSet="material-icons-outlined"
    [class.is-dense]="mode === 'dense'"
    [class.is-warn]="warn"
    [class.is-faded]="iconFaded">
    {{ icon }}
  </mat-icon>
</zui-progress-spinner>

<span
  class="__text"
  [class.is-dense]="mode === 'dense'"
  [class.has-no-icon]="!icon"
  *ngIf="!disableLabel"
  [class.is-warn]="warn">
  <span *ngIf="!!label" [innerHTML]="label"></span>
  <ng-container *ngIf="!label"><ng-content></ng-content></ng-container> <mat-icon
    class="c-action-list-item-after-label-icon  __after-label-icon"
    [ngStyle]="{
      'width.px': mode === 'dense' ? 16 : iconSize,
      'height.px': mode === 'dense' ? 16 : iconSize,
      'lineHeight.px': mode === 'dense' ? 13 : iconSize,
      'fontSize.px': mode === 'dense' ? 16 : iconSize }"
    *ngIf="afterLabelIcon">{{ afterLabelIcon }}</mat-icon>
</span>
